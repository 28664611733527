import { createElement, useEffect, useRef, useState } from "react";
import { useDisclosure } from "hooks/useDisclosure";
import {
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownToggle,
} from "components/molecules/Dropdown";
import AwesomeDebouncePromise from "awesome-debounce-promise";
import { FilterSection } from "./FilterSection";
import { apiPaths, customerApiBase } from "enum/paths.enum";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Loader } from "components/atoms/Loader";
import { fetcher } from "services/api";
import { Center } from "components/atoms/Center";
import { Portal } from "react-portal";
import { Input } from "components/atoms/Input";
import { IconType } from "react-icons";
import { InputElement } from "components/atoms/InputElement";

export const FilterLookup = <T,>({
  title,
  itemComponent,
  valueComponent,
  value,
  onChange,
  icon,
}: {
  title: string;
  itemComponent(item: T): JSX.Element;
  valueComponent(item: T): JSX.Element;
  value: T;
  onChange(item: T): void;
  icon: IconType;
}) => {
  const [isLoading, setLoading] = useState<boolean>(true);

  const { salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const ref = useRef<HTMLInputElement>(null);

  const [query, setQuery] = useState("");

  const { isOpen, onClose, onOpen } = useDisclosure();

  const [data, setData] = useState<T[]>([]);

  const handleChange = (item: T) => {
    setQuery("");

    onChange(item);

    onClose();
  };

  useEffect(() => {
    if (query.length > 0) {
      setLoading(true);

      const asyncFunction = () =>
        fetcher({
          url:
            customerApiBase +
            "/" +
            salesAccountUUID +
            apiPaths.bulkShipments +
            `/lookup?search=${query}`,
          method: "GET",
        }).then((resp) => {
          return resp.data;
        });

      const asyncFunctionDebounced = AwesomeDebouncePromise(asyncFunction, 500);

      asyncFunctionDebounced().then((resp: T[]) => {
        setData(resp);
        onOpen();
        setLoading(false);
      });
    } else {
      setData([]);
      setLoading(false);
      onClose();
    }
  }, [query]);

  return (
    <FilterSection name={title} icon={icon}>
      <div className="relative">
        <Dropdown mode="onClick" isOpen={isOpen} onClose={onClose}>
          <DropdownToggle>
            <div className="relative">
              <InputElement />
              <Input
                value={query}
                ref={ref}
                placeholder="Search"
                onClick={() => {
                  query.length > 0 && onOpen();
                }}
                hasLeftElement={true}
                onChange={(e) => setQuery(e.target.value)}
              />

              {isLoading && (
                <div className="absolute top-0 right-0 h-9 w-9">
                  <Center>
                    <Loader />
                  </Center>
                </div>
              )}
            </div>
          </DropdownToggle>

          <Portal>
            <DropdownMenu width={300}>
              {data && data.length > 0 ? (
                <div
                  className="overflow-scroll"
                  style={{
                    maxHeight: 300,
                  }}
                >
                  {data &&
                    data.map((item, key) => {
                      return (
                        <DropdownMenuItem
                          key={key}
                          onClick={() => {
                            handleChange(item);
                          }}
                        >
                          {createElement(itemComponent, item)}
                        </DropdownMenuItem>
                      );
                      // }
                    })}
                </div>
              ) : (
                <div className="p-3 text-center">Nothing to see here</div>
              )}
            </DropdownMenu>
          </Portal>
        </Dropdown>
      </div>

      {value && (
        <div className="mt-2">{createElement(valueComponent, value)}</div>
      )}
    </FilterSection>
  );
};

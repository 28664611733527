import { Checkbox } from "components/atoms/Checkbox";
import { TableCellIds } from "components/atoms/Table/types";
import { Tooltip } from "components/atoms/Tooltip";
import { ShipmentErrorsTableCell } from "components/organisms/ShipmentErrors/ShipmentErrorsCell";
import { IShipment } from "models/shipment/shipment.model";
import { useDispatch, useSelector } from "react-redux";
import { CellProps, Column } from "react-table";
import { RootState } from "store";
import { setSelectedShipmentIds } from "store/shipmentsSlice/index.slice";
import { getShipmentDirection } from "utils/getShipmentDirection";
import { getTrackingUrl } from "utils/getTrackingUrl";
import { isLabelGeneratingForShipment } from "utils/isLabelGeneratingForShipment";
import { shipmentLabelsInProgress } from "./utils/Shipments.utils";
import { TableTextCell } from "components/atoms/Table/TableTextCell";

export const CheckboxCellColumn = () => {
  const dispatch = useDispatch();

  const { meta, selectedShipmentIds } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const { createLoadingStatus } = useSelector((state: RootState) => {
    return state.labelJobsSlice;
  });

  const { items: labelJobs } = useSelector((state: RootState) => {
    return state.currentLabelJobsSlice;
  });

  const unselectable = [];

  meta?.shipmentIds?.map((id) => {
    if (shipmentLabelsInProgress(labelJobs).includes(id)) {
      unselectable.push(id);
    }
  });

  return {
    id: TableCellIds.CHECKBOX,
    Header: () => {
      return (
        <Checkbox
          testId="checkbox-test"
          disabled={
            createLoadingStatus === "LOADING"
              ? true
              : unselectable.length === meta?.shipmentIds?.length
              ? true
              : false
          }
          checked={
            selectedShipmentIds.length > 0 &&
            selectedShipmentIds.length === meta?.shipmentIds?.length
              ? true
              : selectedShipmentIds.length > 0 &&
                selectedShipmentIds.length < meta?.shipmentIds?.length
              ? "indeterminate"
              : false
          }
          onClick={(e) => {
            e.stopPropagation();
          }}
          onCheckedChange={(state) => {
            const isIndeterminate =
              state === true && selectedShipmentIds.length > 0;

            if (state === false || isIndeterminate) {
              dispatch(setSelectedShipmentIds([]));
            } else {
              dispatch(
                setSelectedShipmentIds(
                  meta?.shipmentIds.filter((id) => !unselectable.includes(id))
                )
              );
            }
          }}
        />
      );
    },
    width: 46,
    maxWidth: 46,
    minWidth: 46,
    Cell: (props: CellProps<IShipment>) => {
      const isDisabled =
        createLoadingStatus === "LOADING"
          ? true
          : isLabelGeneratingForShipment(props.row.original)
          ? true
          : false;

      return (
        <Tooltip
          content="A label is currently being created for this shipment. You cannot delete or edit it until the label has been created."
          isDisabled={!isDisabled}
        >
          <Checkbox
            disabled={isDisabled}
            onClick={(e) => {
              e.stopPropagation();
            }}
            checked={selectedShipmentIds.includes(props.row.original.id)}
            onCheckedChange={() => {
              const isChecked = selectedShipmentIds.includes(
                props.row.original.id
              );

              isChecked
                ? dispatch(
                    setSelectedShipmentIds(
                      selectedShipmentIds.filter(
                        (id) => id !== props.row.original.id
                      )
                    )
                  )
                : dispatch(
                    setSelectedShipmentIds([
                      ...selectedShipmentIds,
                      props.row.original.id,
                    ])
                  );
            }}
          />
        </Tooltip>
      );
    },
  };
};

export const TrackingCellColumn: Column<IShipment> = {
  Header: "Tracking Numbers",
  accessor: "trackingCodes",
  Cell: (props: CellProps<IShipment>) => {
    return (
      <a
        onClick={(e) => {
          e.stopPropagation();
        }}
        className="underline cursor-pointer"
        href={getTrackingUrl(
          props.row.original.trackingCodes[0],
          props.row.original?.carrier?.id,
          getShipmentDirection(
            props.row.original.fromAddressCountryIso,
            props.row.original.toAddressCountryIso
          )
        )}
        target="_blank"
        referrerPolicy="no-referrer"
      >
        {props.row.original.trackingCodes[0]}
      </a>
    );
  },
};

export const TrackingStatusCellColumn: Column<IShipment> = {
  Header: "Tracking Status",
  accessor: "trackingStatus",
  Cell: (props: CellProps<IShipment>) => {
    let value = "";
    const barcodeTracking =
      props.row.original.packageTrackingData.validBarcodes.find(
        (element) => element.barcode === props.row.original.trackingCodes[0]
      );
    const trackingEvents = barcodeTracking?.events
      .map((element) => ({
        trackingEvent: element.trackingEvent,
        statusTime: Date.parse(element.statusTime),
      }))
      .sort((a, b) => b.statusTime - a.statusTime)
      .map((element) => {
        return {
          ...element,
          statusTime: new Date(element.statusTime).toISOString(),
        };
      });
    if (trackingEvents) {
      value = trackingEvents[0].trackingEvent;
    }

    if (value) {
      value = value.split("_").join(" ").toLowerCase();
      value = value.charAt(0).toUpperCase() + value.slice(1);
    }

    return <TableTextCell value={value} />;
  },
};

export const EstimatedDeliveryCellColumn: Column<IShipment> = {
  Header: "Est. Delivery",
  accessor: "estimatedDeliveryDate",
  Cell: (props: CellProps<IShipment>) => {
    return <TableTextCell value={props.row.original.estimatedDeliveryDate} />;
  },
};

export const ErrorsColumn: Column<IShipment> = {
  Header: "Errors",
  accessor: "errors",
  Cell: (props: CellProps<IShipment>) => {
    return <ShipmentErrorsTableCell shipment={props.row.original} />;
  },
};

import { UNHANDLED_ERROR_MESSAGE } from "components/organisms/ShipmentErrors/ShipmentErrors.utils";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { IShipment } from "models/shipment/shipment.model";
import { toast } from "react-hot-toast";
import { fetchShipment } from "services/api/shipments";
import { AppThunk, RootState } from "store";
import {
  setCurrentShipment,
  setCurrentShipmentLoadingStatus,
} from "../index.slice";
import { setDangerousGoodsOption } from "store/createShipmentSlice/index.slice";

export const fetchSingleShipmentThunk = (uuid: IShipment["uuid"]): AppThunk => {
  return async (dispatch, getState) => {
    const {
      authSlice: { salesAccountUUID },
      createShipmentSlice: { dangerousGoodsCategories },
    } = getState() as RootState;

    await dispatch(setCurrentShipmentLoadingStatus(ApiLoadingStatus.LOADING));

    const { data, error } = await fetchShipment(salesAccountUUID, uuid);

    const value = data?.dangerousGoods
      ? dangerousGoodsCategories.find(
          (type) => type.label === data.dangerousGoods
        )
      : null;

    if (!error) {
      dispatch(setCurrentShipment(data));
      dispatch(setDangerousGoodsOption(value));
    }

    if (error) {
      toast.error(UNHANDLED_ERROR_MESSAGE);
    }
  };
};

import { createElement } from "react";
import { IconType } from "react-icons";
import { FilterSection } from "./FilterSection";

export const FilterGeneric = <T extends object>({
  title,
  valueComponent,
  value,
  icon,
}: {
  title: string;
  valueComponent(item: T): JSX.Element;
  value: T;
  icon: IconType;
}) => {
  return (
    <FilterSection name={title} icon={icon}>
      <div className="mt-2">
        {value && createElement(valueComponent, value)}
      </div>
    </FilterSection>
  );
};

import { FilterSection } from "./FilterSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Select } from "components/atoms/Select";
import { IconType } from "react-icons";

export const FilterStoreNameSelection = ({
  title,
  value,
  onChange,
  icon,
}: {
  title: string;
  value: number;
  onChange(item: number): void;
  icon: IconType;
}): JSX.Element => {
  const { allIntegrationStores } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const options = [];
  allIntegrationStores?.forEach((element) => {
    options.push({ label: element.identifier, value: parseInt(element.id) });
  });

  const StoreName =
    options.find((store) => {
      return store.value === value;
    }) || null;

  return (
    <FilterSection name={title} icon={icon}>
      <Select
        value={StoreName}
        isNullable={true}
        onChange={(option) => {
          onChange(option?.value || null);
        }}
        placeholder="Select store name"
        optionTemplate={({ label }) => {
          return (
            <div className="flex items-center">
              <div>{label}</div>
            </div>
          );
        }}
        options={options}
      />
    </FilterSection>
  );
};

import { apiPaths } from "enum/paths.enum";
import { FunctionComponent } from "react";
import { IconType } from "react-icons";

export enum FilterTypes {
  "date" = "date",
  "country" = "country",
  "salesAccount" = "salesAccount",
  "lookup" = "lookup",
  "generic" = "generic",
  "tracking" = "tracking",
  "stores" = "stores",
  "sku" = "sku",
  "quantity" = "quantity",
}

export type FilterBase<T> = {
  component: FunctionComponent<any>;
  icon: IconType;
  title: string;
};

export interface FilterDate<T> extends FilterBase<T> {
  type: FilterTypes.date;
  fromDateValue: string;
  toDateValue: string;
  onChangeFromDate(date: string): void;
  onChangeToDate(date: string): void;
}

export interface FilterGeneric<T> extends FilterBase<T> {
  type: FilterTypes.generic;
  value: T;
  onChange(value: T): void;
  valueComponent(item: T): void;
}

export interface FilterCountry<T> extends FilterBase<T> {
  type: FilterTypes.country;
  value: string;
  onChange(value: string): void;
}

export interface FilterSalesAccount<T> extends FilterBase<T> {
  type: FilterTypes.salesAccount;
  value: string;
  onChange(value: number): void;
}

export interface FilterTracking<T> extends FilterBase<T> {
  type: FilterTypes.tracking;
  value: string;
  onChange(value: string): void;
}

export interface FilterStores<T> extends FilterBase<T> {
  type: FilterTypes.stores;
  value: number;
  onChange(value: number): void;
}

export interface FilterSkus<T> extends FilterBase<T> {
  type: FilterTypes.sku;
  value: string;
  onChange(value: string): void;
}

export interface FilterQuantity<T> extends FilterBase<T> {
  type: FilterTypes.quantity;
  value: string;
  onChange(value: string): void;
}

export interface FilterLookup<T> extends FilterBase<T> {
  type: FilterTypes.lookup;
  value: T;
  endpoint: apiPaths;
  onChange(value: T): void;
  itemComponent(item: T): void;
  valueComponent(item: T): void;
}

export type Filter<T> =
  | FilterGeneric<T>
  | FilterDate<T>
  | FilterLookup<T>
  | FilterCountry<T>
  | FilterSalesAccount<T>
  | FilterTracking<T>
  | FilterStores<T>
  | FilterSkus<T>
  | FilterQuantity<T>;

import { FaSearch } from "react-icons/fa";
import { Center } from "../Center";

export const InputElement = () => {
  return (
    <div className="absolute top-0 left-0 h-9 w-9 text-muted">
      <Center>
        <FaSearch />
      </Center>
    </div>
  );
};

import { createElement, ReactNode } from "react";
import { IconType } from "react-icons";
import { useTheme } from "styled-components";

export type IFilterSectionProps = {
  name: string;
  icon: IconType;
  children: ReactNode;
};

export const FilterSection = ({
  children,
  name,
  icon,
}: IFilterSectionProps) => {
  const theme = useTheme();

  return (
    <div
      className="w-full"
      style={{
        padding: theme.gutter,
      }}
    >
      <div className="flex items-center mb-2 leading-none group text-muted">
        {createElement(icon, {
          className: "mr-2",
          size: 14,
        })}
        <div className="text-sm font-medium leading-none">{name}</div>
      </div>
      <div className="pt-1">{children}</div>
    </div>
  );
};

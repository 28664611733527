import { Quotes } from "components/pages/Quotes";
import { Shipments } from "components/pages/Shipments";
import { NextSeo } from "next-seo";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { isUserAdmin } from "utils/isUserAdmin";

export default function IndexPage(): JSX.Element {
  const { loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  return (
    <>
      {isUserAdmin(loggedInUser) ? (
        <>
          <NextSeo title="Quotes" />
          <Quotes />
        </>
      ) : (
        <>
          <NextSeo title="Shipments" />
          <Shipments />
        </>
      )}
    </>
  );
}

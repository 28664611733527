import { FilterSection } from "./FilterSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Select } from "components/atoms/Select";
import { ISelectOption } from "components/atoms/Select/UseSelect";
import { ICountry } from "models/country/country.model";
import { Flag } from "components/atoms/Flag";
import { IconType } from "react-icons";

export const FilterCountrySelection = ({
  title,
  value,
  onChange,
  icon,
}: {
  title: string;
  value: string;
  onChange(item: string): void;
  icon: IconType;
}): JSX.Element => {
  const { countriesSelectOptions } = useSelector((state: RootState) => {
    return state.countriesSlice;
  });

  const country =
    countriesSelectOptions.find((country) => {
      return country.isoCode === value;
    }) || null;

  return (
    <FilterSection name={title} icon={icon}>
      <Select<ISelectOption<ICountry, string>>
        value={country}
        isNullable={true}
        onChange={(option) => {
          onChange(option?.isoCode || null);
        }}
        placeholder="Select country"
        optionTemplate={({ label, ...rest }) => {
          return (
            <div className="flex items-center">
              <div className="mr-2">
                <Flag countryCode={rest?.isoCode?.toLowerCase()} />
              </div>
              <div>{label}</div>
            </div>
          );
        }}
        options={countriesSelectOptions}
      />
    </FilterSection>
  );
};

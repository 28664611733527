import {
  TabbedNavigation,
  TabbedNavigationItem,
} from "components/organisms/TabbedNavigation";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { useRouter } from "next/router";
import { useSelector } from "react-redux";
import { RootState } from "store";
import {
  FaPrint,
  FaCheckCircle,
  FaExclamationCircle,
  FaTimesCircle,
} from "react-icons/fa";
import { ShipmentStatusCategorySettings } from "models/shipment/shipment.model";
import { calculateDaysBetweenDates } from "utils/calculate/calculateDaysBetweenDates.util";

export const ShipmentsNavigation = () => {
  const {
    query: { status },
  } = useRouter();

  const {
    meta: { statusCount },
    query: {
      filters: { fromDate, toDate },
    },
  } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });
  const activeColor =
    ShipmentStatusCategorySettings[status as string]?.color || "orange";

  const daysBetweenDates = calculateDaysBetweenDates(
    new Date(fromDate),
    new Date(toDate)
  );
  return (
    <TabbedNavigation activeColor={activeColor}>
      <TabbedNavigationItem
        color={ShipmentStatusCategorySettings.TO_FIX.color}
        activeColor={activeColor}
        icon={FaExclamationCircle}
        isActive={status === ShipmentStatusCategoryStrings.TO_FIX}
        href={`?status=${ShipmentStatusCategoryStrings.TO_FIX}`}
        count={statusCount?.error || 0}
        daysBetweenDates={daysBetweenDates}
      >
        To fix
      </TabbedNavigationItem>
      <TabbedNavigationItem
        color={ShipmentStatusCategorySettings.READY.color}
        activeColor={activeColor}
        icon={FaPrint}
        isActive={status === ShipmentStatusCategoryStrings.READY}
        href={`?status=${ShipmentStatusCategoryStrings.READY}`}
        count={statusCount?.ready || 0}
        daysBetweenDates={daysBetweenDates}
      >
        Ready to print
      </TabbedNavigationItem>
      <TabbedNavigationItem
        color={ShipmentStatusCategorySettings.COMPLETE.color}
        activeColor={activeColor}
        icon={FaCheckCircle}
        isActive={status === ShipmentStatusCategoryStrings.COMPLETE}
        href={`?status=${ShipmentStatusCategoryStrings.COMPLETE}`}
        count={statusCount?.complete || 0}
        daysBetweenDates={daysBetweenDates}
      >
        Complete
      </TabbedNavigationItem>
      <TabbedNavigationItem
        color={ShipmentStatusCategorySettings.CANCELLED.color}
        activeColor={activeColor}
        icon={FaTimesCircle}
        isActive={status === ShipmentStatusCategoryStrings.CANCELLED}
        href={`?status=${ShipmentStatusCategoryStrings.CANCELLED}`}
        count={statusCount?.cancelled || 0}
        daysBetweenDates={daysBetweenDates}
      >
        Cancelled
      </TabbedNavigationItem>
    </TabbedNavigation>
  );
};

import { Data } from "components/organisms/Data";
import { IQuoteJob, QuoteModel } from "models/quote/quote.model";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useEffect } from "react";
import {
  setQueryOffset,
  setQuerySearch,
  setQuerySort,
} from "store/quotesSlice/index.slice";
import { openModal } from "store/modalSlice";
import { TableTextCell } from "components/atoms/Table/TableTextCell";
import { CellProps, Column } from "react-table";
import { TableDateCell } from "components/atoms/Table/TableDateCell";
import { Checkmark16, Close16 } from "@carbon/icons-react";
import { isUserAdmin } from "utils/isUserAdmin";
import { fetchQuotesThunk } from "store/quotesSlice/QuotesSliceFetchQuotesThunk.Thunk";
import { fetchSingleQuoteThunk } from "store/quotesSlice/QuotesSliceFetchSingleQuoteThunk.Thunk";
import { TableAddressCell } from "components/atoms/Table/cells/TableAddressCell";
import { QuotesFilters } from "./QuotesFilters";

export const Quotes = () => {
  const { loadingStatus, items, meta, query } = useSelector(
    (state: RootState) => {
      return state.quotesSlice;
    }
  );

  const { loggedInUser, salesAccountUUID } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const { users } = useSelector((state: RootState) => {
    return state.usersSlice;
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      (!isUserAdmin(loggedInUser) && salesAccountUUID) ||
      isUserAdmin(loggedInUser)
    ) {
      dispatch(fetchQuotesThunk());
    }
  }, [salesAccountUUID, query, loggedInUser, dispatch]);

  const handleRowClick = async (quote: IQuoteJob) => {
    dispatch(openModal({ name: "viewQuote" }));
    dispatch(fetchSingleQuoteThunk(quote.uuid));
  };

  const handleRefresh = () => {
    dispatch(fetchQuotesThunk());
  };

  const BaseColumns: Column<IQuoteJob>[] = [
    {
      Header: "Reference",
      accessor: "jobNumber",
      Cell: (props: CellProps<IQuoteJob>) => {
        return <TableTextCell value={props.row.original.jobNumber} />;
      },
    },
    {
      Header: "Created at",
      accessor: "createdDate",
      enableSorting: true,
      Cell: (props: CellProps<IQuoteJob>) => {
        return <TableDateCell value={props.row.original.createdDate} />;
      },
    },
    {
      Header: "From address",
      accessor: "fromAddressZip",
      Cell: (props: CellProps<IQuoteJob>) => {
        return (
          <TableAddressCell
            firstName=""
            lastName=""
            company=""
            zip={props.row.original.fromAddressZip}
            countryIsoCode={props.row.original.fromAddressCountryIso}
          />
        );
      },
    },
    {
      Header: "To address",
      accessor: "toAddressZip",
      Cell: (props: CellProps<IQuoteJob>) => {
        return (
          <TableAddressCell
            firstName=""
            lastName=""
            company=""
            zip={props.row.original.toAddressZip}
            countryIsoCode={props.row.original.toAddressCountryIso}
          />
        );
      },
    },
  ];

  const CustomerQuoteColumns: Column<IQuoteJob>[] = [...BaseColumns];

  const AdminQuoteColumns: Column<IQuoteJob>[] = [
    ...BaseColumns,
    {
      Header: "Customer",
      accessor: "salesAccountId",
      Cell: (props: CellProps<IQuoteJob>) => {
        const customer = salesAccounts.find((salesAccount) => {
          return salesAccount.id === props.row.original.salesAccountId;
        });

        return (
          <TableTextCell
            value={`${customer?.tradeName} (${customer?.salesAccountReference})`}
          />
        );
      },
    },
    {
      Header: "Created by admin",
      accessor: "generatedByAdmin",
      Cell: (props: CellProps<IQuoteJob>) => {
        return (
          <>
            {props.row.original.generatedByAdmin ? (
              <div className="text-green-500">
                <Checkmark16 />
              </div>
            ) : (
              <div className="text-red-500">
                <Close16 />
              </div>
            )}
          </>
        );
      },
    },
    {
      Header: "Created by",
      accessor: "createdBy",
      Cell: (props: CellProps<IQuoteJob>) => {
        const user = users.find(
          (user) => user.id === props.row.original.createdBy
        );

        return <>{user?.email}</>;
      },
    },
  ];

  const downloadOptions = [
    {
      label: "Download Quote Report",
      onClick: () => {
        dispatch(openModal({ name: "quoteReport" }));
      },
    },
  ];

  return (
    <div className="flex flex-col flex-1 w-full">
      <div className="flex flex-1 overflow-hidden">
        <Data
          meta={{
            ...meta,
          }}
          data={items}
          columns={
            isUserAdmin(loggedInUser) ? AdminQuoteColumns : CustomerQuoteColumns
          }
          model={QuoteModel}
          onSearchSubmit={(query) => {
            dispatch(setQuerySearch(query));
          }}
          onNextPage={(page) => {
            dispatch(setQueryOffset(page));
          }}
          onPrevPage={(page) => {
            dispatch(setQueryOffset(page));
          }}
          loadingStatus={loadingStatus}
          sort={query.sort}
          handleColumnSort={(sort) => {
            dispatch(setQuerySort(sort));
          }}
          onItemClick={(row) => {
            handleRowClick(row);
          }}
          allowExport
          onRefresh={handleRefresh}
          filters={QuotesFilters()}
          downloadOptions={downloadOptions}
        />
      </div>
    </div>
  );
};

import { IQuotedRate } from "models/quote/quote.model";
import { fetchAdminQuote, fetchQuote } from "services/api/quotes";
import { AppThunk, RootState } from "store";
import { isUserAdmin } from "utils/isUserAdmin";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { setCurrentQuoteLoadingStatus, setCurrentQuote } from "./index.slice";

export const fetchSingleQuoteThunk = (uuid: IQuotedRate["uuid"]): AppThunk => {
  return async (dispatch, getState) => {
    await dispatch(setCurrentQuoteLoadingStatus(ApiLoadingStatus.LOADING));

    const {
      authSlice: { salesAccountUUID, loggedInUser },
    } = getState() as RootState;

    // if (salesAccountUUID) { This doesn't work for admin users, need to find another way
      try {
        const { data, error } = isUserAdmin(loggedInUser)
          ? await fetchAdminQuote(uuid)
          : await fetchQuote(salesAccountUUID, uuid);

        if (!error) {
          dispatch(setCurrentQuote(data));
        }
      } catch (e) {
        console.log(e);
      }
    // }
  };
};

import { Model } from "../types/model.type";
import { Building24 } from "@carbon/icons-react";
import { apiPaths } from "enum/paths.enum";
import { IShipment } from "models/shipment/shipment.model";
import { PackageTypes } from "enum/package-types.enum";
import { CarrierTypes } from "enum/carrier-types.enum";
import { ITariff } from "models/tariff/tariff.model";
import { ISupplier } from "models/supplier/supplier.model";
import { IService } from "models/service/service.model";
import { ICarrier } from "models/carrier/carrier.model";

export interface SurchargeItem {
  name: string;
  description: string;
  carrierId: string;
  supplierId: number;
  tariffId: number;
  serviceId: number;
  userSelectable: boolean;
  selected: boolean;
  surchargeId: number;
  amount: number;
}

export interface FuelCharge {
  amountMinorUnits: number;
  amount: number;
  percentValueMinorUnit: number;
  percentValueAmount: number;
}

export type IQuotePackage = {
  quantity: number | null;
  type: string | PackageTypes;
  length: number | null;
  width: number | null;
  height: number | null;
  weight: number | null;
};

export type IQuotedRate = {
  id: string;
  uuid: string;
  isSelected: boolean;
  createdDate: string;
  netPrice: string;
  shipmentId: string;
  salesAccountId: string;
  carrierType: CarrierTypes;
  carrier: ICarrier;
  service: IService;
  supplier: ISupplier;
  tariff: ITariff;
  vatCode: string;
  fuelSurchargePercent: string;
  sentToCustomer: boolean;
  allowQuotes: boolean;
  allowLabels: boolean;
};

export type IQuoteJob = IShipment & {
  rates: IQuotedRate[];
};

export const QuoteModel: Model<IQuoteJob> = {
  id: "jobNumber",
  displayKey: "jobNumber",
  name: "Quote",
  icon: Building24,
  searchItem: () => {},
  path: apiPaths.quotes,
  schema: {
    jobNumber: {
      name: "Created at",
      type: "DATE",
    },
  },
};

import { FilterSection } from "./FilterSection";
import { useSelector } from "react-redux";
import { RootState } from "store";
import { Select } from "components/atoms/Select";
import { IconType } from "react-icons";

export const FilterCustomerSelection = ({
  title,
  value,
  onChange,
  icon,
}: {
  title: string;
  value: any;
  onChange(item: number): void;
  icon: IconType;
}): JSX.Element => {
  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const salesAccountsOptions = salesAccounts.map((sa) => {
    return { label: sa.tradeName, value: sa.id };
  });

  return (
    <FilterSection name={title} icon={icon}>
      <Select
        value={value}
        isNullable={true}
        onChange={(option) => {
          onChange(option?.value || null);
        }}
        placeholder="Select customer"
        options={salesAccountsOptions}
      />
    </FilterSection>
  );
};

import { FilterCustomerSelection } from "components/atoms/Table/TableFilter/components/FilterCustomerSelection";
import {
  Filter,
  FilterTypes,
} from "components/atoms/Table/TableFilter/types/filter.types";
import { IShipment } from "models/shipment/shipment.model";
import { FaFile } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { changeCustomerFilterThunk } from "store/quotesSlice/ChangeCustomerQuoteFilterThunk.Thunk";

export const QuotesFilters = () => {
  const dispatch = useDispatch();

  const { salesAccounts } = useSelector((state: RootState) => {
    return state.salesAccountSlice;
  });

  const salesAccountsOptions = salesAccounts.map((sa) => {
    return { label: sa.tradeName, value: sa.id };
  });

  const { query: quotesQuery } = useSelector((state: RootState) => {
    return state.quotesSlice;
  });

  let value = null;

  salesAccountsOptions.forEach((element) => {
    if (quotesQuery.filters.salesAccountId === element.value) {
      value = element;
    }
  });

  const customer: Filter<IShipment> = {
    component: FilterCustomerSelection,
    icon: FaFile,
    type: FilterTypes.salesAccount,
    title: "Customer",
    value: value,
    onChange: (item) => {
      dispatch(changeCustomerFilterThunk(item));
    },
  };

  return { customer };
};

import { setQuerySalesAccountID } from "./index.slice";
import { IQuotesSlice } from "./QuotesSlice.interface";
import { AppThunk } from "store";
import { fetchQuotesThunk } from "store/quotesSlice/QuotesSliceFetchQuotesThunk.Thunk";

export const changeCustomerFilterThunk = (
  salesAccountId: IQuotesSlice["query"]["filters"]["salesAccountId"]
): AppThunk => {
  return async (dispatch) => {
    await dispatch(setQuerySalesAccountID(salesAccountId));

    dispatch(fetchQuotesThunk());
  };
};

import { Input } from "components/atoms/Input";
import { useRef, useState } from "react";
import { useDisclosure } from "hooks/useDisclosure";
import { useHotkeys } from "react-hotkeys-hook";
import { KeyboardKeys } from "enum/keyboardkeys.enum";
import { useOnClickOutside } from "hooks/useOutsideClick";
import { Close20 } from "@carbon/icons-react";
import { Center } from "components/atoms/Center";
import {
  changeSkuFilterThunk,
  changeQuantityFilterThunk,
  fetchShipmentsThunk,
} from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { useDispatch } from "react-redux";

export const FilterSkuQuantitySelection = ({
  sku = "",
  quantity = "",
}: {
  sku: string;
  quantity: string;
}): JSX.Element => {
  const dispatch = useDispatch();
  const { onOpen, onClose } = useDisclosure();
  const [skuValue, setSkuValue] = useState<string>(sku);
  const [quantityValue, setQuantityValue] = useState<string>(quantity);
  const container = useRef<HTMLDivElement>(null);

  useOnClickOutside([container], () => {
    onClose();
  });

  const ref1 = useHotkeys<HTMLInputElement>(
    KeyboardKeys.ENTER,
    () => {
      dispatch(changeSkuFilterThunk(skuValue));
      dispatch(changeQuantityFilterThunk(quantityValue));
      dispatch(fetchShipmentsThunk());
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const ref2 = useHotkeys<HTMLInputElement>(
    KeyboardKeys.ENTER,
    () => {
      dispatch(changeSkuFilterThunk(skuValue));
      dispatch(changeQuantityFilterThunk(quantityValue));
      dispatch(fetchShipmentsThunk());
    },
    {
      enableOnTags: ["INPUT"],
    }
  );

  const handleClear = () => {
    dispatch(changeSkuFilterThunk(""));
    dispatch(changeQuantityFilterThunk(""));
    setSkuValue("");
    setQuantityValue("");
    dispatch(fetchShipmentsThunk());
    ref1?.current?.focus();
    ref2?.current?.focus();
  };

  return (
    <div className="flex-col">
      <div className="mb-2">
        <div className="relative">
          <div className="relative">
            <Input
              value={skuValue}
              onChange={(e) => {
                setSkuValue(e.target.value);
              }}
              onFocus={onOpen}
              ref={ref1}
              placeholder="SKU"
            />
          </div>
          {sku.length > 0 && (
            <div
              className="absolute top-0 right-0 w-10 cursor-pointer h-9"
              onClick={handleClear}
            >
              <Center>
                <Close20 />
              </Center>
            </div>
          )}
        </div>
      </div>
      <div className="">
        <div className="relative" ref={container}>
          <div className="relative">
            <Input
              value={quantityValue}
              onChange={(e) => {
                setQuantityValue(e.target.value);
              }}
              onFocus={onOpen}
              ref={ref2}
              type="number"
              placeholder="Quantity"
            />
          </div>
          {quantity.length > 0 && (
            <div
              className="absolute top-0 right-0 w-10 cursor-pointer h-9"
              onClick={handleClear}
            >
              <Center>
                <Close20 />
              </Center>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

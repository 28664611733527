import { LabelJobStatus } from "enum/label-job-status.enum";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import { isArray } from "lodash";
import { ILabelJob } from "models/labelJob/labelJob.model";
import { IShipment } from "models/shipment/shipment.model";

export const shipmentLabelsInProgress = (
  labelJobs: ILabelJob[]
): IShipment["id"][] => {
  return labelJobs
    .filter((labelJob) => labelJob.status === LabelJobStatus.PENDING)
    .map((labelJob) => labelJob.shipmentIds.map((id) => id))
    .flat(1);
};

export const showBulkActions = (
  status: string | null,
  selectedShipmentIds: IShipment["id"][]
) => {
  return (
    (status === ShipmentStatusCategoryStrings.READY ||
      status === ShipmentStatusCategoryStrings.TO_FIX ||
      status === ShipmentStatusCategoryStrings.COMPLETE) &&
    selectedShipmentIds.length > 0
  );
};

export const getStatusFromQuery = (query: string | string[]): string => {
  return isArray(query) ? query[0] : query;
};

export const isStatusStringValid = (query: string): boolean => {
  return ShipmentStatusCategoryStrings[query] ? true : false;
};

export const isStatusFilterValid = (statuses: string[]): boolean => {
  let isValid = true;

  statuses.map((status) => {
    if (!isStatusStringValid(status)) {
      isValid = false;
    }
  });

  return isValid;
};

import { Data } from "components/organisms/Data";
import { IShipment, ShipmentModel } from "models/shipment/shipment.model";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import {
  createLabelJobThunk,
  setCreateLoadingStatus,
} from "store/labelJobsSlice/index.slice";
import { openModal } from "store/modalSlice";
import {
  setQuerySort,
  setQueryStatusFilter,
  setSelectedShipmentIds,
} from "store/shipmentsSlice/index.slice";
import { BulkActions, ShipmentsBulkActions } from "./ShipmentsBulkActions";
import { ShipmentsNavigation } from "./ShipmentsNavigation";
import { ShipmentStatusCategoryStrings } from "enum/shipment-status-category-string.enum";
import {
  getStatusFromQuery,
  isStatusStringValid,
  showBulkActions,
} from "./utils/Shipments.utils";
import { ShipmentFilters } from "./ShipmentsFilters";
import { paths } from "enum/paths.enum";
import { ApiLoadingStatus } from "enum/api-loading-status.enum";
import { fetchCurrentLabelJobsThunk } from "store/currentLabelJobsSlice/index.slice";
import { GENERIC_TOAST_ERROR_MESSAGE } from "constants/strings";
import { toast } from "react-hot-toast";
import {
  CheckboxCellColumn,
  ErrorsColumn,
  TrackingCellColumn,
  TrackingStatusCellColumn,
  EstimatedDeliveryCellColumn,
} from "./ShipmentsTableCells";
import {
  changePageThunk,
  fetchShipmentsThunk,
  searchShipmentsThunk,
  fetchTrackingStatusesThunk,
  fetchIntegrationStoresThunk,
} from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { fetchSingleShipmentThunk } from "store/shipmentsSlice/thunks/fetchSingleShipmentThunk.thunk";
import { QuerySort } from "interfaces/api.interface";
import { useTheme } from "styled-components";
import { FaTimes } from "react-icons/fa";
import { clearFiltersThunk } from "store/shipmentsSlice/thunks/fetchShipmentsThunk.thunk";
import { PermissionCheck } from "utils/PermissionCheck/PermissionCheck";

export const Shipments = () => {
  const [isMounted, setMounted] = useState(false);

  const dispatch = useDispatch();

  const { createLoadingStatus } = useSelector((state: RootState) => {
    return state.labelJobsSlice;
  });

  const { theme: connexxTheme } = useSelector((state: RootState) => {
    return state.tableSlice;
  });

  const { alertType, loggedInUser } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const permissions = new PermissionCheck({ user: loggedInUser });

  const isOnstop = alertType === "onstop";

  const {
    loadingStatus,
    items,
    meta,
    query: shipmentsQuery,
    selectedShipmentIds,
  } = useSelector((state: RootState) => {
    return state.shipmentsSlice;
  });

  const { userColumns } = useSelector((state: RootState) => {
    return state.authSlice;
  });

  const { query } = useRouter();

  const { push } = useRouter();

  const memoisedData = useMemo(() => items, [selectedShipmentIds, items]);

  const handleItemClick = (item: IShipment) => {
    dispatch(openModal({ name: "viewShipment", type: "drawer" }));
    dispatch(fetchSingleShipmentThunk(item.uuid));
  };

  const handleCreateLabelJob = () => {
    if (isOnstop) {
      dispatch(openModal({ name: "customerOnStopWarning" }));
    } else {
      if (permissions.shipments.canGenerate())
        dispatch(createLabelJobThunk(selectedShipmentIds));
    }
  };

  const handleSearchSubmit = (query: string) => {
    dispatch(searchShipmentsThunk(query));
  };

  const handleRefresh = () => {
    dispatch(fetchShipmentsThunk());
  };

  const handleChangePage = (page: number) => {
    dispatch(changePageThunk(page));
  };

  const handleColumnSort = (sort: QuerySort<IShipment>) => {
    dispatch(setQuerySort(sort));
  };

  const handleDelete = () => {
    if (permissions.shipments.canDelete())
      dispatch(openModal({ name: "confirmBulkDeleteShipmentsModal" }));
  };

  const downloadOptions = [
    {
      label: "Download Shipment Report",
      onClick: () => {
        dispatch(openModal({ name: "shipmentReport" }));
      },
    },
  ];

  useEffect(() => {
    setMounted(true);
    dispatch(fetchTrackingStatusesThunk());
    dispatch(fetchIntegrationStoresThunk());
  }, []);

  useEffect(() => {
    if (isMounted) {
      dispatch(fetchShipmentsThunk());
    }
  }, [shipmentsQuery.filters.statuses, isMounted, shipmentsQuery.sort]);

  useEffect(() => {
    if (
      !query.status ||
      !isStatusStringValid(getStatusFromQuery(query.status))
    ) {
      push({
        href: paths.SHIPMENTS_INDEX,
        query: { status: ShipmentStatusCategoryStrings.READY },
      });
    }

    if (query.status && isStatusStringValid(getStatusFromQuery(query.status))) {
      dispatch(
        setQueryStatusFilter(
          ShipmentStatusCategoryStrings[query.status as string]
        )
      );
    }
  }, [query]);

  useEffect(() => {
    if (createLoadingStatus === ApiLoadingStatus.SUCCESS) {
      dispatch(setSelectedShipmentIds([]));
      dispatch(setCreateLoadingStatus(ApiLoadingStatus.IDLE));
      dispatch(fetchCurrentLabelJobsThunk());
      dispatch(openModal({ name: "labels", type: "drawer" }));
      toast.success("Your labels are being processed");
    }

    if (createLoadingStatus === ApiLoadingStatus.FAILED) {
      toast.error(GENERIC_TOAST_ERROR_MESSAGE);
    }
  }, [createLoadingStatus]);

  const columns = [
    ...(query.status !== ShipmentStatusCategoryStrings.CANCELLED
      ? [CheckboxCellColumn()]
      : []),
    ...(query.status === ShipmentStatusCategoryStrings.TO_FIX
      ? [ErrorsColumn]
      : []),
    ...(query.status === ShipmentStatusCategoryStrings.COMPLETE
      ? [
          TrackingCellColumn,
          TrackingStatusCellColumn,
          EstimatedDeliveryCellColumn,
        ]
      : []),
    ...ShipmentModel.columns,
  ];

  const theme = useTheme();
  const FilterDisclaimer = () => {
    return (
      <div
        className={`rounded-lg flex items-center justify-between m-2 px-2 h-10 w-full text-sm`}
        style={{ backgroundColor: theme.colors.blue[9] }}
      >
        <div className="ml-2 flex-1 md:flex md:justify-between font-bold">
          Filters Active
        </div>
        <div
          className={`rounded-lg flex items-center justify-center cursor-pointer hover:${
            connexxTheme === "dark" ? "bg-black" : "bg-white"
          } p-2`}
          onClick={() => {
            dispatch(clearFiltersThunk());
          }}
        >
          <FaTimes />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col flex-1 w-full">
      <ShipmentsNavigation />
      <div className="flex flex-1 overflow-hidden">
        <div className="flex flex-1 overflow-hidden">
          {showBulkActions(
            getStatusFromQuery(query.status),
            selectedShipmentIds
          ) && (
            <ShipmentsBulkActions
              handleCreateLabelJob={handleCreateLabelJob}
              handleDelete={handleDelete}
              selectedShipmentIds={selectedShipmentIds}
              actions={
                query.status === ShipmentStatusCategoryStrings.READY
                  ? [BulkActions.DELETE, BulkActions.PRINT]
                  : query.status === ShipmentStatusCategoryStrings.TO_FIX
                  ? [BulkActions.DELETE]
                  : query.status === ShipmentStatusCategoryStrings.COMPLETE
                  ? [BulkActions.PRINT, BulkActions.DELETE] // TODO: Add print action but don't print new labels. Just print existing labels.
                  : []
              }
            />
          )}
          <Data<IShipment>
            meta={meta}
            columns={columns}
            sort={shipmentsQuery.sort}
            data={memoisedData}
            filters={ShipmentFilters()}
            onSearchSubmit={handleSearchSubmit}
            onRefresh={handleRefresh}
            onNextPage={handleChangePage}
            onPrevPage={handleChangePage}
            handleColumnSort={handleColumnSort}
            model={ShipmentModel}
            loadingStatus={loadingStatus}
            onItemClick={handleItemClick}
            allowExport={true}
            downloadOptions={downloadOptions}
            FilterComponent={
              shipmentsQuery.filters.trackingStatus ||
              shipmentsQuery.filters.fromAddressCountryIso ||
              shipmentsQuery.filters.toAddressCountryIso ||
              shipmentsQuery.filters.bulkShipment ||
              shipmentsQuery.filters.labelJob
                ? FilterDisclaimer
                : null
            }
            tableRowQuantityChange={() => {
              dispatch(fetchShipmentsThunk());
            }}
            userColumns={userColumns["shipmentsTable"]}
          />
        </div>
      </div>
    </div>
  );
};
